<template>
  <div class="footer p-5 background-blue">
    <footer class="my-lg-0 font-white">
      <div class="container">
        <div class="row text-left justify-content-around">
          <div class="col-lg-3">
            <div class="font-weight-bold mb-lg-3 my-2 sec-title">
              Contact Us
            </div>
            <ul class="list-unstyled">
              <li>Tel: 650-396-3985</li>
              <li>
                Business Info:
                <a class="text-muted" href="mailto:business@ailaw.ai">
                  <span class="font-white">business@ailaw.ai</span></a
                >
              </li>
              <li>
                Technical Info:
                <a class="text-muted" href="mailto:support@ailaw.ai">
                  <span class="font-white">support@ailaw.ai</span></a
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-3">
            <div class="font-weight-bold mb-lg-3 my-2 sec-title">Address</div>
            <p>
              160 East Tasman Drive, Suite 105
              <br />
              San Jose, California 95134
            </p>
          </div>
          <div class="col-lg-3">
            <div class="font-weight-bold mb-lg-3 my-2 sec-title">
              Privacy & Legal
            </div>
            <ul class="list-unstyled mb-4">
              <li>
                <a class="text-muted" href="/terms"
                  ><span class="font-white">Terms of Use</span></a
                >
              </li>
              <li>
                <a class="text-muted" href="/policy"
                  ><span class="font-white">Privacy Policy</span></a
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-3">
            <div class="font-weight-bold mb-lg-3 my-2 sec-title">
              Following Us
            </div>
            <div>
              <a
                target="_blank"
                href="//www.youtube.com/channel/UCHp_lNYKIZSrIR4aAGGcWdQ"
                class="mr-3 social-link"
              >
                <img class="social" src="../assets/youtube.png" />
              </a>
              <a
                target="_blank"
                href="//twitter.com/ailawinc?lang=en"
                class="mr-3 social-link"
              >
                <img class="social" src="../assets/twitter.png" />
              </a>
              <a
                target="_blank"
                href="//www.linkedin.com/company/ailaw-inc"
                class="mr-3 social-link"
              >
                <img class="social" src="../assets/linkedin.png" />
              </a>
              <a
                target="_blank"
                href="//www.facebook.com/ailawinc"
                class="mr-3 social-link"
              >
                <img class="social" src="../assets/facebook.png" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md text-center">
          <small class="d-block mb-3 text-muted copyright"
            ><span class="font-white">Copyright © 2020 AILaw Inc.</span
            ><span class="text-nowrap font-white">
              All rights reserved</span
            ></small
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped lang="scss">
.copyright {
  margin: 3rem;
}

.social-link {
  display: inline-block;
  overflow: hidden;

  .social {
    height: 1.75rem;
    transform: scale(4);
  }
}

@include media-breakpoint-up(lg) {
  .logo {
    font-size: 1.25rem;
  }
  .sec-title {
    font-size: 1.25rem;
  }
}

.font-white {
  color: white;
}

.background-blue {
  background-color: #00054a;
}
</style>
