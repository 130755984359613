<template>
  <div class="mb-2 container scrolnav">
    <div class="pos-f-t">
      <div class="collapse" id="navbarToggleExternalContent" v-if="showMenu">
        <div class="bg-dark pt-5 links-style">
          <router-link class="nav-link tag-style" to="home">
            <span class="font-weight-bold menu-font" @click="pagechange()"
              >Home</span
            >
          </router-link>
          <router-link class="nav-link tag-style" to="trademark">
            <span class="font-weight-bold menu-font" @click="pagechange()"
              >Corporation</span
            >
          </router-link>
          <router-link class="nav-link tag-style" to="immigration">
            <span class="font-weight-bold menu-font" @click="pagechange()"
              >Immigration</span
            >
          </router-link>
          <!--          <router-link class="nav-link tag-style" :to="{name: 'immigration', query: {pricing:1}}">-->
          <!--            <span class="font-weight-bold menu-font" @click="handlePricing">Pricing</span>-->
          <!--          </router-link>-->
          <router-link class="nav-link tag-style" to="trust">
            <span class="font-weight-bold menu-font" @click="pagechange()"
              >Trust</span
            >
          </router-link>
          <router-link class="nav-link tag-style" to="paralegal">
            <span class="font-weight-bold menu-font" @click="pagechange()"
              >Paralegal Service</span
            >
          </router-link>
          <!--          <router-link class="nav-link tag-style" to="corporation">-->
          <!--            <span class="font-weight-bold menu-font" @click="pagechange()">Corporation</span>-->
          <!--          </router-link>-->
          <a
            href="https://www.immigrationlaw.ai/activity/ailaw/demo"
            target="_blank"
            class="tag-style nav-link ml-auto ml-lg-5 font-weight-bold menu-font"
          >
            Demo</a
          >
          <a
            href="https://www.immigrationlaw.ai/signin"
            target="_blank"
            class="login-tag nav-link ml-auto ml-lg-5 font-weight-bold menu-font"
          >
            Log In</a
          >
          <a
            href="https://blog.ailaw.ai/"
            target="_blank"
            class="tag-style nav-link ml-auto ml-lg-5 font-weight-bold menu-font"
          >
            Blog</a
          >
          <a
            href="https://widget.ailaw.ai/"
            target="_blank"
            class="tag-style nav-link ml-auto ml-lg-5 font-weight-bold menu-font"
          >
            Visa Bulletin</a
          >
        </div>
      </div>
    </div>

    <div>
      <nav class="navbar navbar-dark bg-dark navbar-pad">
        <div class="navbar-button">
          <button
            class="navbar-toggler nav-ham"
            @click="myshowmenu()"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="home-label">
            <router-link class="nav-link navbar-brand labelsize" to="home">
              <img
                v-if="isImmigrationPage"
                class="img-fluid nav-logo"
                src="../assets/ailaw_immigration_page_logo.png"
              />
              <img
                v-else-if="isParalegalPage"
                class="img-fluid nav-logo"
                src="../assets/ailaw_paralegal_page_logo.png"
              />
              <img
                v-else
                class="img-fluid nav-logo"
                src="../assets/ailaw_main_logo.png"
              />
            </router-link>
          </div>
        </div>
      </nav>
    </div>

    <nav class="navbar navbar-expand navbar-dark pc-navbar">
      <div class="navbar-nav mr-auto">
        <router-link
          class="nav-link navbar-brand labelsize shownavi navi-icon"
          to="home"
        >
          <div class="mx-2" style="margin-top: -3.5px">
            <img
              v-if="isImmigrationPage"
              class="img-fluid nav-logo"
              src="../assets/ailaw_immigration_page_logo.png"
            />
            <img
              v-else-if="isParalegalPage"
              class="img-fluid nav-logo"
              src="../assets/ailaw_paralegal_page_logo.png"
            />
            <img
              v-else
              class="img-fluid nav-logo"
              src="../assets/ailaw_main_logo.png"
              style="margin-right: 48px"
            />
          </div>
        </router-link>
        <div
          class="dropdown dropdown-wrap"
          @mouseleave="showDropdown0 = false"
          @mouseover="showDropdown0 = true"
        >
          <router-link
            id="dropdownMenuButton1"
            :class="{ active: showDropdown0 }"
            class="nav-router-tag btn dropdown-toggle"
            to="home"
          >
            <span class="font-weight-bold home-font">Home</span>
          </router-link>
        </div>
        <div
          class="dropdown dropdown-wrap"
          @mouseleave="showDropdown = false"
          @mouseover="showDropdown = true"
        >
          <div
            id="dropdownMenuButton"
            :class="{ active: showDropdown }"
            class="btn dropdown-toggle"
            style="width: 120px"
          >
            <span class="font-weight-bold home-font">Software</span>
          </div>
          <div class="dropdown-menu dropdown-menu2" v-show="showDropdown">
            <router-link to="immigration" class="dropdown-item"
              >Immigration</router-link
            >
            <router-link to="trademark" class="dropdown-item"
              >Corporation</router-link
            >
            <router-link to="trust" class="dropdown-item">Trust</router-link>
          </div>
        </div>
        <div
          class="dropdown dropdown-wrap"
          @mouseleave="showDropdown1 = false"
          @mouseover="showDropdown1 = true"
        >
          <router-link
            id="dropdownMenuButton1"
            :class="{ active: showDropdown1 }"
            class="nav-router-tag btn dropdown-toggle"
            to="paralegal"
          >
            <span class="font-weight-bold home-font">Paralegal Service</span>
          </router-link>
        </div>
        <!--        <router-link class="nav-link shownavi" to="corporation">-->
        <!--          <span class="font-weight-bold home-font">Corporation</span>-->
        <!--        </router-link>-->
      </div>
      <div class="pc-navbar-right navbar-nav ml-auto shownavi font-weight-bold">
        <a
          href="https://www.immigrationlaw.ai/signin"
          target="_blank"
          class="nav-link ml-auto ml-lg-5 home-font"
        >
          Log In</a
        >
        <a
          href="https://www.immigrationlaw.ai/activity/ailaw/demo"
          target="_blank"
          class="nav-link ml-auto ml-lg-5 home-font"
        >
          Demo</a
        >
        <!--        <a href="https://blog.ailaw.ai/" target="_blank"-->
        <!--           class="nav-link ml-auto ml-lg-5 home-font">-->
        <!--          Blog</a>-->
      </div>
      <div
        class="dropdown dropdown-wrap"
        @mouseleave="showDropdown3 = false"
        @mouseover="showDropdown3 = true"
      >
        <router-link
          id="dropdownMenuButton4"
          :class="{ active: showDropdown3 }"
          class="btn dropdown-toggle"
          to="immigration"
        >
          <span class="font-weight-bold home-font">News Center</span>
        </router-link>
        <div class="dropdown-menu dropdown-menu2" v-show="showDropdown3">
          <a
            href="https://blog.ailaw.ai/"
            target="_blank"
            style="cursor: pointer"
            class="dropdown-item"
            >Blog</a
          >
          <a
            href="https://widget.ailaw.ai/"
            target="_blank"
            style="cursor: pointer"
            class="dropdown-item"
            >Visa Bulletin</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import jump from 'jump.js'
export default {
  name: 'Navi',
  data() {
    return {
      showMenu: false,
      showDropdown0: false,
      showDropdown: false,
      showDropdown1: false,
      showDropdown2: false,
      showDropdown3: false,
    }
  },
  computed: {
    hasOngingOrder: function() {
      return localStorage.customer !== undefined
    },
    isImmigrationPage() {
      return this.$route.name === 'immigration'
    },
    isParalegalPage() {
      return this.$route.name === 'paralegal'
    },
  },
  watch: {
    $route() {
      this.showDropdown = false
    },
  },
  methods: {
    handlePricing() {
      this.showMenu = false
      this.$router
        .push({ name: 'immigration', query: { pricing: 1 } })
        .catch(err => {})
      setTimeout(() => {
        jump('.pricing-title')
      }, 500)
    },
    myshowmenu() {
      this.showMenu = true
    },
    pagechange() {
      this.showMenu = false
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style scoped lang="scss">
.navi-icon {
  line-height: 60px;
}

.pc-navbar-right .ml-lg-5 {
  margin-left: 1rem !important;
}

nav.pc-navbar {
  padding: 0 1rem;
  /*line-height: 60px;*/
  .navbar-nav {
    > a,
    > div {
      padding: 0 0.5rem;
    }
  }
  #dropdownMenuButton {
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    padding: 0.375rem 0.75rem !important;
  }
  #dropdownMenuButton4 {
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    padding: 0.375rem 0.75rem !important;
  }
  .nav-router-tag {
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    padding: 0.375rem 0.75rem !important;
  }
}
.pc-navbar-right {
}
.dropdown-wrap {
  height: 57px;
  line-height: 57px;
}
.dropdown-wrap:hover {
  background-color: #f56123;
}
.router-link-border:after {
  background-color: #f56123;
}
div.dropdown-menu2 {
  padding-bottom: 0;
  background-color: #f56123;
  position: absolute;
  top: 95%;
  left: 0;
  display: block;
  width: 100%;
  border-radius: 0;
  border: none;
  color: #fff;
  a {
    color: #fff;
  }
  a:hover,
  a:active,
  a:focus {
    background-color: rgba(255, 255, 255, 0.582);
    color: #f56123 !important;
    font-weight: bold;
  }
}

#dropdownMenuButton:focus {
  outline: none;
  box-shadow: none;
}
#dropdownMenuButton4:focus {
  outline: none;
  box-shadow: none;
}

#dropdownMenuButton:after {
  vertical-align: 0;
  transition: all 0.3s linear;
  border: none;
  margin: 0;
  left: 50%;
  right: 50%;
}
#dropdownMenuButton4:after {
  vertical-align: 0;
  transition: all 0.3s linear;
  border: none;
  margin: 0;
  left: 50%;
  right: 50%;
}

.nav-router-tag:after {
  vertical-align: 0;
  transition: all 0.3s linear;
  border: none;
  margin: 0;
  left: 50%;
  right: 50%;
}

.nav-router-tag:focus {
  outline: none;
  box-shadow: none;
}

#dropdownMenuButton.active:after {
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  height: 2px;
  background-color: #382bcc;
}
#dropdownMenuButton4.active:after {
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  height: 2px;
  background-color: #382bcc;
}

.nav-router-tag.active:after {
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  height: 2px;
  background-color: #382bcc;
}

.dropdown-menu2 {
  min-width: 8rem;
}

.testdisplaynav {
  display: inline-block;
}

.scrolnav {
  /* overflow-x: auto; */
}

.navbar-pad {
  padding: 0px !important;
  display: none;
}

.links-style {
  display: none;
  top: 2.25rem;
}

.shownavi {
  display: flex;
}

.tag-style {
  border-top: 1px solid gray;
  width: 70%;
  margin: auto;
  margin-top: 0.75rem;
}

@media screen and (max-width: 1023px) {
  .testdisplaynav {
    display: none;
  }

  .scrolnav {
    display: flex;
    /* overflow-x: scroll; */
    white-space: nowrap;
  }

  .nav-ham {
    float: right;
    border: 0px;
  }
}

img {
  height: 26px;
}

@include media-breakpoint-up(lg) {
  img {
    height: 26px;
  }

  span {
    vertical-align: middle;
    font-size: 1.25rem;
  }
}

.router-link-active {
  border-bottom-color: #001bff;
  border-bottom-width: 1px;
}

.home-font {
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .mx-2 {
    display: none;
  }

  div.scrolnav {
    width: 100%;
    max-width: none;
  }
}

@media only screen and (max-width: 850px) {
  .labelsize {
    width: 80%;
    text-align: center;
    margin-left: 1rem;
  }
  .pc-navbar {
    display: none;
  }
  .home-font {
    font-size: 1rem;
    color: white;
  }

  .menu-font {
    font-size: 1.5rem;
    color: white;
  }

  .navbar-pad {
    display: inline-block;
  }

  .navbar-button {
    position: fixed;
    z-index: 30;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $navi-background-color !important;
  }

  .login-tag {
    position: absolute;
    top: 0;
    right: 1rem;
  }

  .shownavi {
    display: none;
  }

  .links-style {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    text-align: left;
    display: inline-block;
    background-color: $navi-background-color !important;
  }
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.nav-logo {
  height: 26px;
}
</style>
