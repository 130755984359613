import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'navigator-active',
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/',
      component: () => import('@/views/index'),
      name: 'home',
    },
    {
      path: '/immigration',
      component: () => import('@/views/Immigration'),
      name: 'immigration',
    },
    // {
    //   path: '/corporation',
    //   component: () => import('@/components/Corporation'),
    //   name: 'corporation'
    // },
    {
      path: '/trademark',
      component: () => import('@/components/Trademark'),
      name: 'trademark',
    },
    {
      path: '/trust',
      component: () => import('@/components/Trust'),
      name: 'trust',
    },
    {
      path: '/terms',
      component: () => import('@/components/Static/Terms'),
    },
    {
      path: '/policy',
      component: () => import('@/components/Static/Policy'),
    },
    {
      name: 'paralegal',
      path: '/paralegal',
      component: () => import('@/views/paralegal/index.vue'),
    },
  ],
})
