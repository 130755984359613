<template>
  <div id="app">
    <div id="content" class="container-fluid content p-0">
      <Navi class="top"/>
      <router-view/>
      <Footer/>
    </div>
  </div>
</template>

<script>
  import Navi from './components/Navi'
  import Footer from './components/Footer'

  export default {
    components: {
      Navi,
      Footer
    }
  }
</script>

<style lang="scss" scoped>

.content {
  max-width: 1366px;
  background-color: #F0F0F0;
}

.top {
  z-index: 255;
}

@media (max-width: 576px) {
  .top {
    padding-left: 1.75rem;
  }
}
</style>
