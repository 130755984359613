import Vue from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretLeft, faCaretRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import router from './router'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'



library.add(faCaretLeft)
library.add(faCaretRight)
library.add(faQuestionCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)
// import { createProvider } from './vue-apollo'

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(VueAwesomeSwiper)

new Vue({
    el: '#app',
    router,
    // apolloProvider: createProvider(),
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
})
